/*
====================================
Medium Screen - Others
====================================
*/
@media screen and (min-width: 992px) and (max-width: 1199px) {

	.header-right ul li {
	    padding-left: 21px;
	}

	.header-right ul li a {
		font-size: 15px;
	}

	.header-cnt {
	    padding: 220px 0 300px;
	}

	.header-cnt p {
		font-size: 48px;
	}

	.header-left img {
	    max-width: 185px;
	}

	.header-cnt::after {
		height: 200px;
	}

	.about-area {
		padding: 70px 0;
	}

	.about-right {
		padding-left: 30px;
	}

	.about-left {
		padding-right: 0;
	}

	.about-right h2 {
		font-size: 40px;
	}

	.about-right p {
		font-size: 21px;
	}

	.serv-cnt {
		padding: 20px 28px;
	}

	.method-cnt {
        padding: 20px 30px;
    }

    .method-cnt h4 {
        font-size: 40px;
    }

    .method-cnt h3,
    .method-cnt p,
    .method-cnt ul li {
        font-size: 20px;
    }

    .gallery-slider {
    	padding-top: 80px;
    }

    .gallery-arrow .gallery-left,
    .gallery-arrow .gallery-right {
    	max-width: 75px;
    }

}

/*
====================================
Small Screen - Tablate
====================================
*/
@media screen and (min-width: 768px) and (max-width: 991px) {

	.hamburger-btn {
		display: block !important;
	}

	.header-right {
		display: none;
	}

	.header-cnt {
	    padding: 200px 0 260px;
	}

	.header-cnt p {
		font-size: 42px;
	}

	.header-cnt p br {
		display: none;
	}

	.header-cnt::after {
		display: none;
	}

	.about-area {
		padding: 65px 0;
	}

	.about-right {
		padding-left: 10px;
	}

	.about-left {
		padding-right: 0;
	}

	.about-right h2 {
		font-size: 32px;
	}

	.about-right p {
		font-size: 16px;
	}

	.serv-upper h2 {
		font-size: 40px;
	}

	.serv-upper p,
	.serv-upper h6 {
		font-size: 21px;
	}

	.service-area {
		padding: 65px 0 75px;
	}

	.serv-cnt {
		padding: 15px 15px;
	}

	.serv-cnt p {
		font-size: 15px;
	}

	.habit-item {
		max-width: 640px;
	}

	.habit-item p {
		font-size: 16px;
	}

	.habit-upper h2 {
		font-size: 40px;
	}

	.habit-slider .left {
		left: -15px;
	}

	.habit-slider .right {
		right: -15px;
	}

	.method-cnt {
        padding: 20px 20px;
    }

    .method-cnt h4 {
        font-size: 28px;
    }

    .method-cnt h3,
    .method-cnt p,
    .method-cnt ul li {
        font-size: 15px;
    }

    .method-cnt,
    .method-item img  {
    	height: 420px;
    }

    .method-cnt ul li {
    	padding-left: 15px;
    }

    .method-cnt ul li::before {
    	width: 6px;
    	height: 6px;
    	top: 10px;
    	left: 0;
    }

    .inspo-upper h2,
    .consult-part h2 {
    	font-size: 40px;
    }

    .consult-part p {
    	font-size: 20px;
    }

    .gallery-slider {
    	padding-top: 70px;
    	padding-left: 15px;
    	padding-right: 15px;
    }

    .gallery-arrow .gallery-left {
    	max-width: 60px;
    	left: -24px;
    }

    .gallery-arrow .gallery-right {
    	max-width: 60px;
    	right: -20px;
    }

    .gallery-upper h2 {
    	font-size: 40px;
    }

    .gallery-area {
    	padding: 60px 0 100px;
    }

    .footer-item ul li a,
	.footer-item a  {
		font-size: 15px;
	}

	.footer-item span {
		left: 48%;
	}

	.copyright-part p {
		font-size: 15px;
	}

	
}

/*
====================================
Small Screen - Mobile
====================================
*/
@media screen and (max-width: 767px) {
	.hamburger-btn {
		display: block;
	}

	.header-right {
		display: none;
	}

	.header-cnt {
	    padding: 180px 0 200px;
	}

	.header-cnt p {
		font-size: 32px;
	}

	.header-cnt p br {
		display: none;
	}

	.header-left img {
	    max-width: 200px;
	}

	.header-cnt::after {
		display: none;
	}

	.about-area {
		padding: 65px 0;
	}

	.about-right {
		padding-left: 0px;
		margin-top: 30px;
	}

	.about-left {
		padding-right: 0;
	}

	.about-right h2 {
		font-size: 36px;
	}

	.about-right p {
		font-size: 18px;
	}

	.serv-upper h2 {
		font-size: 36px;
	}

	.serv-upper p,
	.serv-upper h6 {
		font-size: 18px;
	}

	.serv-upper p br {
		display: none;
	}

	.service-area {
		padding: 65px 0 75px;
	}

	.serv-main {
	    padding-top: 0px;
	}

	.serv-item {
		margin-top: 50px;
	}

	.habit-area {
		padding: 70px 0 60px;
	}

	.habit-slider {
		margin-top: 32px;
	}

	.habit-item {
		max-width: 640px;
		padding: 0 10px;
	}

	.habit-item p {
		font-size: 15px;
		margin-top: 20px;
	}

	.habit-upper h2 {
		font-size: 32px;
	}

	.habit-item h4 {
		font-size: 17px;
	}

	.habit-slider .left {
		max-width: 12px;
		left: -8px;
	}

	.habit-slider .right {
		max-width: 12px;
		right: -8px;
	}

	.method-cnt {
        padding: 45px 20px;
        margin-top: -5px;
    }

    .method-cnt h4 {
        font-size: 28px;
    }

    .method-cnt h3,
    .method-cnt p,
    .method-cnt ul li {
        font-size: 17px;
    }

    .method-cnt,
    .method-item img  {
    	height: initial;
    }

    .method-cnt ul li::before {
    	top: 10px;
    }

    .order1 {
    	order: 1;
    }

    .order2 {
    	order: 2;
    }

    .personal-cnt h4,
    .proces-cnt h4 {
    	font-size: 25px;
    }

    .proces-cnt p {
	    margin-top: 8px;
	}

	.inspo-upper h2 {
    	font-size: 30px;
    }

    .inspo-upper h4 {
    	font-size: 21px;
    }

    .inspo-upper p {
    	font-size: 17px;
    }

    .inspo-upper img {
    	margin: 24px 0;
    }

    .inspo-upper a {
    	font-size: 14px;
    }

    .consult-part h2 {
    	font-size: 30px;
    }

    .consult-part p {
    	font-size: 17px;
    }

    .consult-part span {
    	height: 120px;
    }

    .gallery-slider {
    	padding-top: 35px;
    	padding-left: 15px;
    	padding-right: 15px;
    }

    .gallery-arrow .gallery-left {
    	max-width: 40px;
    	left: -10px;
    	top: 56%;
    }

    .gallery-arrow .gallery-right {
    	max-width: 40px;
    	right: -10px;
    	top: 56%;
    }

    .gallery-upper h2 {
    	font-size: 30px;
    }

    .gallery-area {
    	padding: 50px 0 80px;
    }

    .footer-main {
    	flex-wrap: wrap;
    }

    .footer-fst {
    	order: 2;
    	text-align: center;
    }

    .footer-mdl {
    	order: 1;
    	margin-bottom: 40px;
    	text-align: center;
    	width: 100%;
    }

    .footer-itemlst {
    	order: 3;
    	text-align: center;
    }

    .footer-item span {
    	height: 52px;
    	left: 50%;
    }

    .footer-mdl a br {
    	display: block;
    	margin: 0;
    }

    .footer-mdl a {
    	margin: 0;
    	line-height: 13px;
    }

    .footer-area {
    	padding-top: 60px;
    }

    .footer-item ul li a,
	.footer-item a  {
		font-size: 15px;
	}

	.copyright-part p {
		font-size: 15px;
	}

	.footer-item .instagram,
	.footer-item .instagram img {
		max-width: 25px;
	}

	.copyright-part {
		padding-top: 17px;
		border-width: 1px;
		margin-top: 35px;
	}

}


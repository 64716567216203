/*=== Fonts ===*/
@font-face {
    font-family: RoxboroughCF;
    src: url('../fonts/RoxboroughCF-Thin.otf');
    font-weight: 200;
}

@font-face {
    font-family: RoxboroughCF-Light;
    src: url('../fonts/RoxboroughCF-Light.otf');
    font-weight: 300;
}

@font-face {
    font-family: SweetSansPro;
    src: url('../fonts/SweetSansProThin.otf');
    font-weight: 200;
}

@font-face {
    font-family: SweetSansPro;
    src: url('../fonts/SweetSansProLight.otf');
    font-weight: 300;
}

@font-face {
    font-family: SweetSansPro;
    src: url('../fonts/SweetSansProRegular.otf');
    font-weight: 400;
}

@font-face {
    font-family: SweetSansPro;
    src: url('../fonts/SweetSansProMedium.otf');
    font-weight: 500;
}

@font-face {
    font-family: SweetSansPro;
    src: url('../fonts/SweetSansProBold.otf');
    font-weight: 700;
}

body {	
    font-family: SweetSansPro;
    font-size: 16px;
    font-weight: 400;    
	background: #fff;
	color: #191919;	
    margin: 0;
    padding: 0;
}

@-ms-viewport {
    width: device-width;
}

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

a {
    display: inline-block;
    text-decoration: none;
    transition: 0.2s all ease;
    -webkit-transition: 0.2s all ease;
    -moz-transition: 0.2s all ease;
}

a:visited,
a:hover,
a:active {
    text-decoration: none;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    margin-bottom: 0;
}

.logo-title {
    font-family: RoxboroughCF;
    font-size: 36px;
    color: #fff;
}

.logo-title-brown {
    font-family: RoxboroughCF;
    font-size: 36px;
    color: #A77B62;
}

/*=== header-area start ===*/
.header-area {
    background-image: url(../images/header-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.header-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    position: absolute;
    width: 70%;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 10;
}

.header-left img {
    max-width: 200px;
}

.header-right ul li {
    display: inline-block;
    padding-left: 34px;
}

.header-right ul li a {
    font-size: 16px;
    font-weight: 400;
    color: #F2E8DF;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    transition: 0.2s all ease;
}

.header-right ul li a:hover,
.header-right ul li #active {
    border-color: #F2E8DF;
    color: #FFF;
}

.header-cnt {
    padding: 300px 0 350px;
    position: relative;
}

.header-cnt::after {
    content: "";
    width: 2px;
    height: 250px;
    display: block;
    background: #F2E8DF;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}

.header-cnt p {
    font-family: RoxboroughCF;
    font-weight: 300;
    font-size: 54px;
    color: #F2E8DF;
    text-align: center;
    margin: 0;
}

.stylist-header-area {
    background-image: url(../images/gallery-2.jpg);
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: top;
}

.mr-10 {
    margin-right: 10px;
}

.salon-container {
    margin-top: 40px;
}

@media screen and (min-width: 1800px) {
    .header-cnt {
        padding: 350px 0 470px;
    }

    .header-cnt p {
        font-size: 65px;
    }

    .header-cnt::after {
        height: 320px;
    }
}

/*hamburger*/
.hamburger-btn {
    cursor: pointer;
    display: none;
}

.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, 
.hamburger-inner::before, 
.hamburger-inner::after {
    width: 40px;
    height: 3px;
    background-color: #F2E8DF;
    border-radius: 4px;
    position: absolute;
}

.hamburger-inner::before, 
.hamburger-inner::after {
    content: "";
    display: block;
    outline: none; 
}

.hamburger-inner::before {
    top: -10px; 
}

.hamburger-inner::after {
    bottom: -10px; 
}

/*=== side-menu start ===*/
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #F2E8DF;
    overflow-x: hidden;
    transition: 0.35s;
    padding-top: 24px;
    z-index: 99;
}

.sidenav ul li {
    display: block;
    padding: 15px 24px;
    text-align: center;
}

.sidenav ul li a {
    font-size: 17px;
    font-weight: 400;
    color: #A77B62;
    display: block;
    transition: 0.2s all ease;
    border-bottom: 1px solid transparent;
}

.sidenav ul li a img {
    max-width: 45px;
}

.side-upper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
}

.mobile-navigation-list {
    margin-top: 50px;
}

.sidenav .closebtn {
    color: #A77B62;  
    font-size: 36px;
}

.sidenav a:hover {
    color: #875F4A;
}

.reveal {
    width: 100%;
}

/*=== about-area start ===*/
.about-area {
    background: #F2E8DF;
    padding: 80px 0;
}

.about-left img {
    max-width: 100%;
}

.about-right h2 {
    font-family: RoxboroughCF;
    font-size: 48px;
    font-weight: 300;
    color: #242424;
}

.about-right p {
    font-size: 28px;
    font-weight: 300;
    color: #242424;
    margin-top: 26px;
}

.about-left {
    padding-right: 30px;
}

.about-right {
    padding-left: 60px;
}

/*=== service-area start ===*/
.service-area {
    background: #F2E8DF;
    padding: 75px 0 85px;
}

.serv-upper h2 {
    font-family: RoxboroughCF;
    font-size: 48px;
    font-weight: 300;
    color: #242424;
    text-align: center;
}

.serv-upper h6 {
    font-size: 28px;
    font-weight: 300;
    color: #242424;
    margin-top: 26px;
    text-align: center;
    font-style: italic;
}

.serv-upper p {
    font-size: 28px;
    font-weight: 300;
    color: #242424;
    text-align: center;
}

.serv-main {
    padding-top: 115px;
}

.serv-item {
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 168px 172px 0 0;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

.serv-item img {
    width: 100%;
    border-radius: 168px 172px 0 0;
}

.serv2-box {
    margin-top: -66px;
}

.serv-cnt {
    background: #A87C61;
    padding: 20px 40px;
    text-align: center;
    margin-top: -8px;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.serv-cnt p {
    font-size: 17px;
    font-weight: 300;
    color: #F2E8DF;
    text-align: center;
    margin: 0;
}

/*=== habit-area start ===*/
.habit-area {
    background-image: url(../images/habit-bg.png
    );
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 100px 0; 
}

.habit-upper h2 {
    font-family: RoxboroughCF;
    font-size: 48px;
    font-weight: 300;
    color: #F2E8DF;
    text-align: center;
}

.habit-slider {
    max-width: 870px;
    margin: 0 auto;
    position: relative;
    margin-top: 50px;
    text-align: center;
}

.habit-slider .left {
    max-width: 14px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.habit-slider .right {
    max-width: 14px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.habit-item {
    max-width: 690px;
    margin: 0 auto;
    text-align: center;
}

.habit-item p {
    font-size: 17px;
    font-weight: 300;
    color: #F2E8DF;
    text-align: center;
    margin-top: 24px;
}

.habit-item h4 {
    font-family: RoxboroughCF;
    font-size: 19px;
    font-weight: 300;
    color: #F2E8DF;
    text-align: center;
    margin-top: 35px;
}

.habit-item h6 {
    font-size: 12px;
    font-weight: 300;
    color: #F2E8DF;
    text-align: center;
}

/*=== method start ===*/
.method-area {
    overflow: hidden;
    /*background: #A87C61;*/
}

.method-item img {
    width: 100%;
}

.method-cnt h4 {
    font-family: RoxboroughCF;
    font-size: 48px;
    font-weight: 300;
    color: #F2E8DF;
    margin-bottom: 20px;
}

.method-cnt p {
    font-size: 24px;
    font-weight: 300;
    color: #F2E8DF;
}

.method-cnt h3 {
    font-size: 24px;
    font-weight: 500;
    color: #F2E8DF;
    margin: 0;
}

.method-cnt ul li {
    font-size: 24px;
    font-weight: 300;
    color: #F2E8DF;
    padding-left: 18px;
    position: relative;
}

.method-cnt ul li::before {
    content: "";
    width: 7px;
    height: 7px;
    display: block;
    background: #F2E8DF;
    border-radius: 50%;
    position: absolute;
    left: 3px;
    top: 15px;
}

.method-item img {
    height: 600px;
    object-fit: cover;
}

.method-cnt {
    background: #A87C61;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 600px;
    padding: 20px 56px;
}

@media screen and (min-width: 1200px) and (max-width: 1350px) {

    .method-cnt {
        padding: 20px 45px;
    }

    .method-cnt h4 {
        font-size: 40px;
    }

    .method-cnt h3,
    .method-cnt p,
    .method-cnt ul li {
        font-size: 20px;
    }
}

.header-menu {
    width: 80%;
}


/*personal-area start*/
.personal-area,
.proces-area {
    margin-top: -5px;
}
.personal-cnt {
    background: #F2E8DF;
}

.personal-cnt h4,
.personal-cnt p {
    color: #242424;
    margin-top: 10px;
}

.proces-cnt p {
    margin-top: 15px;
}

/*=== inspo-area start ===*/
.inspo-area {
    background: #F2E8DF;
    padding: 75px 0;
    margin-top: -5px;
}

.inspo-upper {
    text-align: center;
}

.inspo-upper h2 {
    font-family: RoxboroughCF;
    font-size: 48px;
    font-weight: 300;
    color: #242424;
    text-align: center;
}

.inspo-upper img {
    width: 100%;
    margin: 30px 0;
}

.inspo-upper h4 {
    font-size: 25px;
    font-weight: 300;
    color: #242424;
    text-align: center;
    margin: 0;
}

.inspo-upper p {
    font-size: 20px;
    font-weight: 300;
    color: #242424;
    text-align: center;
}

.inspo-upper a {
    font-size: 15px;
    font-weight: 300;
    color: #F5F6F1;
    background: #A87C61;
    display: inline-block;
    padding: 8px 35px;
    margin-top: 20px;
    transition: 0.2s all ease;
}

.inspo-upper a:hover {
    opacity: 0.85;
}

.consult-part span {
    width: 2px;
    height: 200px;
    background: #A87C61;
    display: block;
    margin: 30px auto;
}

.consult-part {
    text-align: center;
    max-width: 1090px;
    margin: 0 auto;
}

.consult-part h2 {
    font-family: RoxboroughCF;
    font-size: 48px;
    font-weight: 300;
    color: #242424;
    text-align: center;
}

.consult-part p {
    font-size: 24px;
    font-weight: 300;
    color: #242424;
    text-align: center;
    margin-top: 21px;
}

.consult-part a {
    font-size: 15px;
    font-weight: 300;
    color: #F5F6F1;
    background: #A87C61;
    display: inline-block;
    padding: 8px 35px;
    margin-top: 20px;
    transition: 0.2s all ease;
}

.consult-part a:hover {
    opacity: 0.85;
}

/*=== gallery-area start ===*/
.gallery-area {
    background: #5E3E2D;
    padding: 75px 0 160px;
    overflow: hidden;
}

.gallery-upper h2 {
    font-family: RoxboroughCF;
    font-size: 48px;
    font-weight: 300;
    text-align: center;
    color: #F2E8DF;
}

.gallery-slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 100px;
    position: relative;
    padding-left: 42px;
    padding-right: 42px;
}

.gallery-item {
    width: 33.3333%;
}

.gallery-item img {
    width: 100%;
}

.gallery2 img {
    transform: scale(1.3);
}

.gallery-arrow .gallery-left {
    max-width: 85px;
    position: absolute;
    left: -12px;
    top: 50%;
    cursor: pointer;
}

.gallery-arrow .gallery-right {
    max-width: 85px;
    position: absolute;
    right: -8px;
    top: 50%;
    cursor: pointer;
}

/*=== footer-area start ===*/
.footer-area {
    background: #F2E8DF;
    padding: 75px 0 20px;
    position: relative;
}

.footer-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-item ul li a,
.footer-item a {
    font-size: 16px;
    font-weight: 400;
    color: #242424;
    transition: 0.2s all ease;
}

.footer-item ul li a:hover,
.footer-item a:hover {
    color: #000;
}

.footer-item .instagram {
    max-width: 30px;
    display: block;
    margin: 10px auto;
}

.footer-item .instagram img {
    max-width: 30px;
}

.footer-itemlst {
    text-align: right;
}

.footer-item span {
    width: 2px;
    height: 80px;
    display: block;
    background: #000000;
    position: absolute;
    top: 0;
    left: 49%;
    transform: translateX(-49%);
}

.copyright-part p {
    font-size: 16px;
    font-weight: 400;
    clear: #242424;
    text-align: center;
    margin: 0;
}

.copyright-part {
    padding-top: 20px;
    border-top: 2px solid #000000;
    margin-top: 50px;
}

.footer-mdl a br {
    display: none;
}

.stylist-container {
    margin-top: 25px;
    padding-bottom: 75px;
    background: linear-gradient(180deg, rgba(242,232,223,1) 25%, rgba(229,166,125,1) 50%, rgba(168,124,97,1) 100%);
}

.stylist-header {
    font-family: RoxboroughCF;
    font-size: 48px;
    font-weight: 300;
    color: #242424;
    text-align: center;
    margin-bottom: 50px;
}

.stylist-item {
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 168px 172px 0 0;
    text-align: center;
}
.stylists-image {
    width: 75%;
    border-radius: 168px 172px 0 0;
}

.stylist-name {
    font-size: 28px;
    font-weight: 300;
    color: #242424;
    margin-top: 5px;
    text-align: center;
    font-style: italic;
}

.stylist-location {
    color: #242424;
    font-size: 16px;
    margin-bottom: 20px;
}

.stylist-badge {
    margin-top: -150px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}

.stylist-badge img {
    margin-top: -145px;
    width: 10%;
}

.stylist-area {
    background-image: url(../images/stylist-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 150px;
    padding-bottom: 150px;
    height: 80vh;
}

.stylist-header-cnt {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #242424;
    font-family: RoxboroughCF;
    letter-spacing: 1.3px;
    border-radius: 8px;
    padding: 25px 15px;
}

.stylist-information {
    width: 35%;
}

.single-stylist-name {
    color: #ffffff;
    font-size: 28px;
    font-weight: 300;
    margin-top: 5px;
    font-style: italic;
}

.single-stylist-item {
    max-width: 340px;
    border-radius: 168px 172px 0 0;
    text-align: center;
}

.single-stylist-job {
    color: #ffffff;
    font-size: 22px;
}

.stylist-meta {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    width: 100%;
}

.stylist-experience {
    color: #caa183;
    text-align: center;
    font-size: 22px;
    font-family: SweetSansPro;
    font-weight: 700;
}

.day {
    color: #caa183;
    text-align: center;
    font-size: 16px;
    font-family: SweetSansPro;
    font-weight: 700;
}

.day:not(:last-child) {
    margin-right: 15px;
}

.stylist-category {
    color: #f2e8df;
    font-family: SweetSansPro;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.stylist-workdays {
    color: #caa183;
}

.stylist-days {
    display: flex;
    text-align: center;
}

.stylist-skills {
    background-color: #a87c61;
    width: 75%;
    margin: -7px auto;
    padding: 10px 10px;
}

.stylist-skill-list {
    font-family: SweetSansPro;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: #ffffff;
}

.skills {
    border: 1px solid #ffffff;
    border-radius: 8px;
    padding: 8px 15px;
    font-size: 13px;
    margin-top: 10px;
}

.skills:not(:last-child) {
    margin-right: 8px;
}

.single-stylist-fact {
    color: #ffffff;

}

.stylist-center {
    text-align: center;
}


.salon-header-area {
    background-image: url(../images/salon.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
} 

.salon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.single-salon-container {
    width: 30%;
}

.single-salon-container:not(:last-child) {
    margin-right: 40px;
}

.salon-image {
    width: 100%;
}

.salon-meta-container {
    padding: 10px 15px;
}

.salon-title {
    font-family: RoxboroughCF;
    font-size: 30px;
    font-weight: 300;
    color: #242424;
}

.salon-address {
    font-family: SweetSansPro;
    font-size: 16px;
    font-weight: 300;
}

.salon-phone {
    display: block;
    text-decoration: none;
    color: black;
}

.start-consultation {
    display: block;
    font-size: 15px;
    font-weight: 300;
    text-transform: uppercase;
    color: #F5F6F1;
    background: #A87C61;
    display: inline-block;
    padding: 8px 35px;
    margin-top: 20px;
    transition: 0.2s all ease;
}

.book-appointment {
    display: block;
    font-size: 15px;
    font-weight: 300;
    text-transform: uppercase;
    color: #F5F6F1;
    background: #A87C61;
    display: inline-block;
    padding: 8px 25px;
    margin-top: 20px;
    transition: 0.2s all ease;
}

.book-appointment:not(:last-child) {
    margin-right: 15px;
}

.start-consultation:hover {
    opacity: 0.85;
}

.salon-margin {
 margin-top: 50px;
} 

.learn-container {
    display: flex;
}

.learn-header-cnt {
    padding: 300px 0 350px;
    position: relative;
    background-color: #a87c61;
    width: 100%;
}

.learn-header-cnt h6 {
    font-family: RoxboroughCF;
    font-weight: 300;
    font-size: 45px;
    color: #F2E8DF;
    text-align: center;
    margin: 0;
}

.learn-header-cnt p {
    font-family: sweetSansPro;
    font-size: 16px;
    color: #F2E8DF;
    text-align: center;
    padding: 10px 20px;
    font-weight: 300;
}

.learn-videos-container {
    background-color: #f2e8df;
    padding-top: 50px;
    padding-bottom: 50px;
}

.assistant-videos-container {
    background-color: #f2e8df;
    padding-top: 100px;
    padding-bottom: 50px;
    height: 80vh;
}

.select-video {
    display: flex;
    width: 75%;
    margin: 0 auto;
    padding: 25px 7px;
    border-radius: 8px;
    -webkit-box-shadow: 3px 2px 26px 3px rgba(0,0,0,0.39); 
    box-shadow: 3px 2px 26px 3px rgba(0,0,0,0.39);
}

.vimeo-container {
    width: 65%;
    margin-right: 50px;
}

.video-list {
    padding-right: 50px;
    font-size: 18px;
    color: #a87c61;
}

.video-list li {
    cursor: pointer;
}

.video-list li:not(:last-child) {
    margin-bottom: 8px;
}

.video-list span {
  margin-right: 10px;
}

.video-title {
    font-family: RoxboroughCF;
    font-weight: 300;
    font-size: 35px;
    color: #000;
    text-align: center;
    margin: 0;
    padding-bottom: 15px;
}

.extension-list {
    display: block;
    color: #a87c61; 
}

.extension-list:hover {
    text-decoration: underline;
}

.active-player {
    text-decoration: underline;
}

.brand-container {
    width: 50%;
    -webkit-box-shadow: 0px 0px 19px 5px rgba(0,0,0,0.2); 
    box-shadow: 0px 0px 19px 5px rgba(0,0,0,0.2);
    margin: 40px auto;
    padding: 15px 20px;
    border-radius: 8px;
}

.brand-meta {
    display: flex;
    justify-content: center;
}

.brand-description {
    font-family: SweetSansPro;
    font-size: 16px;
    margin-top: 20px;
}

.brand-logo {
    width: 15%;
}

.brand-website {
    text-decoration: underline;
    color: black;
}

.cta-container {
    text-align: center;
}

.cta-btn {
    font-size: 15px;
    font-weight: 300;
    color: #242424;
    background: #F2E8DF;
    padding: 8px 35px;
    margin-top: 20px;
    transition: 0.2s all ease;
}

.stylist-name-container {
    display: flex;
    align-items: center;
}

.instagram-stylist {
    width: 50%;
    filter: invert(1);
    margin-left: 15px;
}

.education-container {
    padding-top: 100px;
    padding-bottom: 27px;
    background-image: url('https://cdn.shopify.com/s/files/1/0315/9133/4026/t/35/assets/abstract.jpg');
    background-position: center;
    background-size: cover;
}

.education-title {
    font-family: RoxboroughCF;
    font-weight: 300;
    font-size: 54px;
    color: #F2E8DF;
    text-align: center;
    margin: 0;
    font-size: 40px;
    padding: 10px 20px;
}

.educator-container {
    display: flex;
    width: 50%;
    margin: 0 auto;
    padding: 30px 20px;
}


.educator-image {
    width: 50%;
}

.education-description-title {
    font-family: RoxboroughCF;
    font-weight: 300;
    color: #000000;
    text-align: center;
    margin: 0;
    font-size: 40px;
    padding: 10px 20px;
}

.education-description {
    font-size: 18px;
    font-weight: 300;
}

.educator-description-container {
    margin-left: 50px;
}

.available-classes {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hc-class-images {
    width: 50%;
}

.location-container {
    height: 350px;
    width: 23%;
    margin: 0 auto;
    border-radius: 10px;
    background-size: cover; 
    position: relative;
    margin-bottom: 25px;
}

.location-meta-container {
    width: 95%;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform:translateX(-50%);
    border-radius: 10px;
    background-color: white;
    padding: 10px 10px;
}

.location-title {
    font-size: 20px;
}

.travel-instagram img {
    width: 50%;
}

.stylist-location {

}

@media only screen and (min-width: 1000px) and (max-width: 1400px )  {
    .educator-container {
        width: 100%;
    }
}


@media only screen and (max-width: 999px) {
    .header-menu {
		width: 90%;
	}

    .educator-image {
        width: 100%;
    }

    .location-container {
        width: 90%;
    }

    .educator-description-container {
        margin-left: 0px;
    }

    .educator-container {
        width: 100%;
        flex-direction: column;
    }

    .available-classes {
        flex-direction: column;
    }
    .hc-class-images {
        width: 100%;
        padding: 10px 10px;
    }

    .stylist-name-container {
       justify-content: center;
    }

    .stylist-header-cnt {
        flex-direction: column;
    }

    .stylist-information {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .stylist-meta {
        flex-direction: column;
    }

    .stylist-days {
       flex-wrap: wrap;
    }

    .stylists-image {
        width: 90%;
    }

    .stylist-skills {
        width: 90%;
    }

    .salon-container {
        flex-direction: column;
    }

    .single-salon-container:not(:last-child) {
        margin-right: 0px;
    }

    .single-salon-container {
        width: 100%;
        margin-top: 50px;
    }

    .learn-container {
        flex-direction: column;
    }

   .learn-header-cnt {
       margin-top: -87px;
       padding: 0;
   }

   .learn-image {
       width: 100%;
   }

   .learn-header-cnt h6 {
     font-size: 30px;
   }

   .learn-videos-container {
    padding-top: 0;
   }

   .vimeo-container {
       width: 100%;
       margin-right: 0;
   }
   .select-video {
       box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0);
       flex-direction: column;
       align-items: center;
       width: 100%;
   }
    
   .brand-container {
       width: 95%;
   }

   .brand-logo {
       width: 50%;
   }

   .stylist-badge img {
    margin-top: -51px;
    width: 35%;
}

}

.book-container {
    margin-right: 10px;
}

.assistant-no-content {
    text-align: center;
    font-size: 16px;
}
.assistant-no-content a {
    text-decoration: underline;
    font-size: 16px;
    color: #000000;
}


.privacy-policy-container {
    width: 50%;
    margin: 50px auto;
}

.full-height {
    height: 100vh;
}

@media only screen and (max-width: 800px) {
    .privacy-policy-container {
        width: 90%;
    }

    .stylist-area {
        height: 100%;
    }

    .habit-btn-containers {
        flex-direction: column;
    }
}

.habit-btn-containers {
    display: flex;
    align-items: center;
    justify-content: center;
}